// const BASE_URL = 'http://localhost:8765'; 
const BASE_URL = 'https://admin.sathemetalwares.com'; 

export const AUTHENTICATE_URL = BASE_URL + '/api/Items/getItems';
export const GET_PRODUCT_LIST = BASE_URL + '/api/Products/getProductLists';
export const GET_CATEGORY_LIST = BASE_URL + '/api/Categories/getCategories';
export const GET_PRODUCT_DETAIL = BASE_URL + '/api/Products/getProductDetail';
export const GET_LATEST_PRODUCTS = BASE_URL + '/api/Products/getLatestProducts';
export const GET_ALL_PRODUCTS = BASE_URL + '/api/Products/getAllProduct';
export const GET_MIX_DATA = BASE_URL + '/api/Products/getMixData';
export const ADD_CONTACT = BASE_URL + '/api/Contacts/contact';
export const URL = 'https://sathemetalwares.com';

