import { GET_CATEGORY_LIST, GET_LATEST_PRODUCTS, GET_PRODUCT_DETAIL, GET_PRODUCT_LIST,GET_ALL_PRODUCTS,GET_MIX_DATA, URL, ADD_CONTACT } from "../../constants/URLConstant";
import {AUTHENTICATE} from "../../constants/WebConstant";
import { addContact } from "../actions/auth";

const initialState = {
    token: null,
    productList: [],
    categoryList: [],
    productDetail: null,
    LatestProducts: [],
    allProduct:[],
    mixData:[],
    login:null,
    addContact:null,
};

export default (state = initialState, action) => {
    switch(action.type){
        case AUTHENTICATE:
            return {
                ...state,
                token: state.token + action.payload,
            }
            case GET_PRODUCT_LIST:
                return {
                    ...state,
                    productList: action.payload
                }
                case GET_CATEGORY_LIST:
                return {
                    ...state,
                    categoryList: action.payload
                }
                case GET_PRODUCT_DETAIL:
                return {
                    ...state,
                    productDetail: action.payload
                }
                case GET_LATEST_PRODUCTS:
                return {
                    ...state,
                    LatestProducts: action.payload
                }
                case URL:
                return {
                    ...state,
                    login: action.payload
                }
                case GET_ALL_PRODUCTS:
                return {
                    ...state,
                    allProduct: action.payload
                }
                case GET_MIX_DATA:
                return {
                    ...state,
                    mixData: action.payload
                }
                case ADD_CONTACT:
                return {
                    ...state,
                    addContact: action.payload
                }
        default:
            return state;
    }
}